<template>
  <div class="card p-3 shadow-sm">
    <form @submit.prevent="addCauseDetails">
      <div class="row" v-if="isAllowed('accident-incident.cause.edit') && isOwnVessel()">
      <div class="col-12 text-right">


          <span class="mr-3"><b>Note</b> : The structure and description of Immediate Cause and Root Cause based on the DNV Marine Systematic Cause.</span>
          <button class="e-btn e-btn-outlined-red ml-2" type="button" v-if="toEdit" @click="cancel">Cancel
          </button>
          <button class="e-btn e-btn-green ml-2" type="button" v-else @click="editValues">Edit Causes</button>
          <button class="e-btn e-btn-blue ml-2" type="submit" v-if="toEdit">Save</button>
        </div>
      </div>
      <table class="w-100">
        <tr>
          <td>
            <h6 class="font-weight-bold">Immediate Cause</h6>
          </td>
          <td>
            <span class="text-danger font-weight-bolder">The cause directly led to occurrence accident/incident/equipment failure and matching answer of question : <br>
              What action/factor/event directly cause this accident / incident / equipment failure ?</span>
          </td>
        </tr>
        <tr>
          <td style="width: 25%">a) Substandard Act <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td v-if="toEdit">
            <select class="form-control form-control-sm text-uppercase" v-model="cause.immediate_cause_act_id">
              <option :value="null">—— select substandard act ——</option>
              <option v-for="act in immediateActsMain" :key="act.id" :value="act.id">
                {{ act.series + '_' }}
                {{ act.name }}
              </option>
            </select>

            <select v-if="immediateActsSub.length" class="form-control form-control-sm mt-1 text-uppercase"
                    v-model="cause.immediate_cause_act_child_id">
              <option :value="null">—— select substandard act ——</option>
              <option v-for="act in immediateActsSub" :key="act.id" :value="act.id">
                {{ act.series + '_' }}
                {{ act.name }}
              </option>
            </select>
          </td>
          <td v-else>
            {{
              accidentIncidentReport.cause.immediateCause ? accidentIncidentReport.cause.immediateCause : ''
            }}
            {{ accidentIncidentReport.cause.immediateCauseChild ? '/' : '' }}
            {{
              accidentIncidentReport.cause.immediateCauseChild ? accidentIncidentReport.cause.immediateCauseChild : ''
            }}
          </td>
        </tr>
        <tr>
          <td>b) Substandard Condition</td>
          <td v-if="toEdit">
            <select class="form-control form-control-sm text-uppercase" v-model="cause.immediate_cause_sub_condition_id">
              <option :value="null">—— select substandard act ——</option>
              <option v-for="condition in immediateConditionsMain" :key="condition.id" :value="condition.id">
                {{ condition.series + '_' }}
                {{ condition.name }}
              </option>
            </select>
          </td>
          <td v-else>
            {{
              accidentIncidentReport.cause.immediateCauseSubCon ? accidentIncidentReport.cause.immediateCauseSubCon : ''
            }}
<!--            {{ accidentIncidentReport.immediate_condition_sub_detail ? '/' : '' }}-->
<!--            {{-->
<!--              accidentIncidentReport.immediate_condition_sub_detail ? accidentIncidentReport.immediate_condition_sub_detail.name : ''-->
<!--            }}-->
          </td>
        </tr>
        <tr>
          <td>Cause Detail</td>
          <td v-if="toEdit">
            <textarea name="" rows="10" class="form-control form-control-sm pre-wrap-txt"
                      v-model="cause.immediate_cause_details"></textarea>
          </td>
          <td v-else>
            <p class="pre-wrap-txt textarea-display-card">
              {{ accidentIncidentReport.cause.immediate_cause_details ? accidentIncidentReport.cause.immediate_cause_details : '' }}
            </p>
          </td>
        </tr>
      </table>
      <hr/>
      <table class="w-100">
        <tr>
          <td>
            <h6 class="font-weight-bold">Root Cause</h6>
          </td>
          <td>
            <span class="text-danger font-weight-bolder">Which factor shall be removed so that such incident / accident / equipment failure will never be happened again ? <br>
             Ask “Why ?” “Why ?” “Why ?” “Why ?” “Why ?” Until you get to the root of the problem ( 5 Whys).</span>
          </td>
        </tr>
        <tr>
          <td style="width: 25%">a) Root Cause <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td v-if="toEdit">
            <select class="form-control form-control-sm text-uppercase" v-model="cause.root_cause_id">
              <option :value="null">—— select root cause ——</option>
              <option v-for="factor in basicCauseMain" :key="factor.id" :value="factor.id">
                {{ factor.series + '_' }}
                {{ factor.name }}
              </option>
            </select>
          </td>
          <td v-else>
            {{
              accidentIncidentReport.cause.rootCause ? accidentIncidentReport.cause.rootCause : ''
            }}
          </td>
        </tr>
        <tr>
          <td style="width: 25%">b) Factor of Root Cause</td>
          <td v-if="toEdit">
            <select class="form-control form-control-sm text-uppercase" v-model="cause.root_cause_factor_id">
              <option :value="null">—— select sub factors ——</option>
              <option v-for="factor in basicCauseSub" :key="factor.id" :value="factor.id">
                {{ factor.series + '_' }}
                {{ factor.name }}
              </option>
            </select>
          </td>
          <td v-else>
            {{
              accidentIncidentReport.cause.rootCauseFactor ? accidentIncidentReport.cause.rootCauseFactor : ''
            }}
          </td>
        </tr>
        <tr>
          <td>Cause Detail</td>
          <td v-if="toEdit">
            <textarea name="" id="" rows="10" class="form-control form-control-sm pre-wrap-txt"
                      v-model="cause.root_cause_details"></textarea>
          </td>
          <td v-else>
            <p class="pre-wrap-txt textarea-display-card">
            {{ accidentIncidentReport.cause.root_cause_details ? accidentIncidentReport.cause.root_cause_details : ''}}
            </p>
          </td>
        </tr>
      </table>
      <template v-if="isAllowed('accident-incident.cause.human-error.view')">
        <hr/>
        <table class="w-100">
          <tr>
            <td colspan="2">
              <h6 class="font-weight-bold">Human Error Classification</h6>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Human Error - Personal</td>
            <td v-if="toEdit">
              <select class="form-control form-control-sm text-uppercase" v-model="cause.human_error_personal_id">
                <option :value="null">—— Select Human Error – Personal ——</option>
                <option v-for="personal in errorPersonalKeys" :key="personal.id" :value="personal.id">
                  {{ personal.code ? personal.code + ' — ' : '' }}
                  {{ personal.name }}
                </option>
              </select>
            </td>
            <td v-else>
              {{accidentIncidentReport.cause.humanErrorPersonal ? accidentIncidentReport.cause.humanErrorPersonal : '' }}
            </td>
          </tr>
          <tr>
            <td>Human Error - Managing / Manning Company</td>
            <td v-if="toEdit">
              <select class="form-control form-control-sm text-uppercase" v-model="cause.human_error_manning_id">
                <option :value="null">—— Select Managing / Manning Company ——</option>
                <option v-for="manning in errorManningKeys" :key="manning.id" :value="manning.id">
                  {{ manning.code ? manning.code + ' — ' : '' }}
                  {{ manning.name }}
                </option>
              </select>
            </td>
            <td v-else>
              {{accidentIncidentReport.cause.humanErrorManning ? accidentIncidentReport.cause.humanErrorManning : '' }}
            </td>
          </tr>
          <tr>
            <td>Human Error - 3rd Party</td>
            <td v-if="toEdit">
              <select class="form-control form-control-sm text-uppercase" v-model="cause.human_error_third_party_id">
                <option :value="null">—— Select 3rd Party ——</option>
                <option v-for="otherParty in errorOtherPartyKeys" :key="otherParty.id" :value="otherParty.id">
                  {{ otherParty.code ? otherParty.code + ' — ' : '' }}
                  {{ otherParty.name }}
                </option>
              </select>
            </td>
            <td v-else>
              {{accidentIncidentReport.cause.humanErrorThirdParty ? accidentIncidentReport.cause.humanErrorThirdParty : '' }}
            </td>
          </tr>
        </table>
      </template>
    </form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {ReportService} from '@/services/ReportService';
import {AlertService} from '@/services/AlertService';
import {permissionMixin} from "@/mixins/permissionMixins";

export default {
  name: 'Cause',
  mixins:[permissionMixin],
  data() {
    return {
      immediateActsMain: {},
      immediateActsSub: {},
      immediateConditionsMain: {},
      immediateConditionsSub: {},

      basicCauseMain: {},
      basicCauseSub: {},
      cause:{},
      reportId: this.$route.params.id,
      toEdit: false
    }
  },
  methods: {
    ...mapActions(['getMainCauseKeys', 'getSubCauseKeys', 'getAccidentIncidentReport', 'getErrorOtherKeys', 'getErrorPersonalKeys', 'getErrorManningKeys','updateAccidentIncidentCause']),
    async addCauseDetails() {
      if (await AlertService.confirmUpdateAlert()) {
        this.cause.id = this.reportId
        if(await this.updateAccidentIncidentCause(this.cause)){
          await AlertService.successAlert('Updating Cause Details', 'UPDATE')
          this.toEdit = false
        }
      }
    },
    async editValues() {
      this.toEdit = true
      if (this.toEdit) {
        this.cause = Object.assign({}, this.accidentIncidentReport.cause)
      }
    },
    // get immediate substandard act main dropdown
    async getImmediateActsMain() {
      const params = {
        cause_type_id: 2,
        cause_type_class_id: 1
      }
      await this.getMainCauseKeys(params)
      this.immediateActsMain = this.mainCauseKeys
    },
    // get immediate substandard condition main dropdown
    async getImmediateConsMain() {
      const params = {
        cause_type_id: 2,
        cause_type_class_id: 2
      }
      await this.getMainCauseKeys(params)
      this.immediateConditionsMain = this.mainCauseKeys
    },
    // get root basic cause
    async getBasicCauseMain(){
      const params = {
        cause_type_id: 1,
        cause_type_class_id: 0
      }
      await this.getMainCauseKeys(params)
      this.basicCauseMain = this.mainCauseKeys
    },
    async cancel(){
      if(await AlertService.cancelAlert()){
        this.toEdit=false;
      }
    }
  },
  async created() {
    await this.getImmediateActsMain()
    await this.getImmediateConsMain()
    await this.getErrorPersonalKeys()
    await this.getErrorManningKeys()
    await this.getErrorOtherKeys()
    await this.getBasicCauseMain()
  },
  computed: {
    ...mapGetters(['mainCauseKeys', 'subCauseKeys', 'accidentIncidentReport', 'errorOtherPartyKeys', 'errorManningKeys', 'errorPersonalKeys'])
  },
  watch: {
    'cause.immediate_cause_act_id': async function () {
      if (this.cause.immediate_cause_act_id === 36) {
        const params = {
          main_cause_id: this.cause.immediate_cause_act_id
        }
        // this.cause.immediate_cause_sub_condition_id=null;
        await this.getSubCauseKeys(params)
        this.immediateActsSub = this.subCauseKeys
      } else {
        this.immediateActsSub = {}
      }
    },
    'cause.root_cause_id': async function() {
      const params = {
        main_cause_id: this.cause.root_cause_id
      }
      // this.cause.root_cause_factor_id=null;
      await this.getSubCauseKeys(params)
      this.basicCauseSub = this.subCauseKeys
    }
  }
}
</script>

<style scoped>
table td {
  padding: 2px;
}
</style>
